import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { device } from "../theme/breakpoints"

const StyledSection = styled.section`
    display: grid;
    grid-template-columns: 1fr;
    @media ${device.laptop} {
        grid-template-columns: 1fr 1fr;
    }
`
const ContentContainer = styled.div`
    order: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    /* @media (min-width: 100px) {
        padding: 1rem 0;
    } */

    & > div {
        padding: var(--space-l) var(--space-side);
        max-width: 600px;
        @media ${device.desktop} {
            max-width: 900px;
        }
    }
    & p {
        margin-bottom: var(--space-m);
    }
    @media ${device.laptop} {
        text-align: left;
        order: -1;
    }
`

const Schutzbrief = (props) => (
    <StyledSection>
        <Img
            fluid={props.data.imageOne.childImageSharp.fluid}
            alt="Oldtimersammlung in einer Garage"
        />
        <ContentContainer>
            <div>
                <h2>Schutzbrief</h2>
                <p>
                    Jeder Oldtimer ist auch immer ein bisschen Diva. Mal streikt
                    die Batterie, mal der Anlasser. Oder die Benzinpumpe versagt
                    ihren Dienst. Und das meist kurz vor der geplanten Tour. Mit
                    dem BELMOT Schutzbrief sind Sie auf alle Allüren bestens
                    vorbereitet, z.B. mit der Pannen- und Unfallhilfe am
                    Schadenort oder dem Transport nach Fahrzeugausfall.{" "}
                    <i>
                        Ein Highlight: Abschleppkosten werden bis zu 1.500 €
                        ersetzt!
                    </i>
                </p>
                {/* <Button primary="primary" link="/anfrage">
                    Jetzt anfragen
                </Button> */}
            </div>
        </ContentContainer>
    </StyledSection>
)

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                imageOne: file(relativePath: { eq: "schutzbrief.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1024) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `}
        render={(data) => <Schutzbrief data={data} {...props} />}
    />
)
